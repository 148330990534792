import React, { useEffect, useRef, useState } from 'react'
import * as range from 'lodash/range'

const UpSpin = (props) => <span style={{fontWeight: "bold", color: "green"}}>↑</span>;
const DownSpin = (props) => <span style={{fontWeight: "bold", color: "red"}}>↓</span>;

const cellSize = 60;

export const IsingTable = (props) => {
    const [state, setState] = useState(true);
    return (
        <table style={{marginLeft: "auto", marginRight: "auto", width: `${cellSize * props.n}px`, height: `${cellSize * props.n}px`}} onClick={() => setState(!state)}>
            <tbody>
                {range(props.n).map((i) => 
                <tr key={i}>
                    {range(props.n).map((j) =>
                        <td style={{border: "1px dashed #ccc", textAlign: "center", padding: 0}} key={`${i}_${j}`}>{
                            ((props.anti && Boolean((i + j) % 2) == state)
                            || (!props.anti && state)) ? <UpSpin /> : <DownSpin />
                        }</td>)
                    }
                </tr>
                )}
            </tbody>
        </table>
    );
}

// https://jsbin.com/mutiworape/edit?html,output
export const IsingTriangle = (props) => {
    const canvasRef = useRef(null);
    const configurations = [
        [true, true, false],
        [true, false, true],
        [false, true, true],
        [false, false, true],
        [false, true, false],
        [true, false, false],
    ]
    const [state, setState] = useState(0);
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const charSize = 40; // Size of a character in pixels
        const edgeLength = charSize * 3; // Edge length of the triangle
        const radius = edgeLength / (2 * Math.sin(Math.PI / 3)); // Distance from center to each vertex

        // Calculate positions for the vertices of the equilateral triangle
        const vertices = [
            { x: centerX, y: centerY - radius }, // Top vertex
            { x: centerX - radius * Math.sin(Math.PI / 3), y: centerY + radius / 2 }, // Bottom left
            { x: centerX + radius * Math.sin(Math.PI / 3), y: centerY + radius / 2 }  // Bottom right
        ];

        // Draw triangle outline with dashed lines
        ctx.setLineDash([5, 5]); // Dashed line pattern
        ctx.strokeStyle = '#333333';
        ctx.beginPath();
        ctx.moveTo(vertices[0].x, vertices[0].y);
        vertices.forEach(({ x, y }) => ctx.lineTo(x, y));
        ctx.closePath();
        ctx.stroke();

        // Draw characters at vertices
        ctx.setLineDash([]); // Reset line dash for text
        ctx.font = `${charSize}px Arial`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        for (let i = 0; i < 3; i++) {
            if (configurations[state][i]) {
                ctx.fillStyle = 'green';
                ctx.fillText('↑', vertices[i].x, vertices[i].y); 
            } else {
                ctx.fillStyle = 'red';
                ctx.fillText('↓', vertices[i].x, vertices[i].y);
            }
        }
    }, [state]);
    return <div style={{textAlign: "center"}}>
        <canvas ref={canvasRef} width={props.width || 180} height={props.height || 180} onClick={() => setState((state + 1) % configurations.length)}></canvas>
    </div>
}
